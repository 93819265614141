<template>
  <div class="next-event">
    <div class="next-event-header">
      <section class="main-info">
        <FlagCategoryTournament :event="getNextEvent" />
      </section>
      <section class="display-time-info">
        <div class="display-id">
          <span>{{ translate('eventId') }}</span>
          <span>{{ getNextEvent.lotteryDisplayId }}</span>
        </div>
        <div class="left-time">
          <span>{{ $store.getters.translate('startingIn') }}</span>
          <RemainingTime />
        </div>
      </section>
    </div>
    <div class="next-event-body">
      <Market :market="getMarket(getNextEvent.markets, '832')"
              :secondary-name="translate('draw')" />
      <section class="betting-shortcuts-title">
        <p class="betting-shortcuts"
           :class="{active: !transitionActive && bettingShortcuts}">
          {{ translate('bettingShortcuts') }}
        </p>
      </section>
      <section class="even-total"
               :class="{active: !transitionActive}">
        <div class="even-odd">
          <Market :market="getMarket(getNextEvent.markets, '821')"
                  :outcomes-round-border="false"
                  :betting-shortcuts="bettingShortcuts"
                  :column="2" />
        </div>
        <div class="sum-total">
          <Market :market="getMarket(getNextEvent.markets, '822')"
                  :betting-shortcuts="bettingShortcuts"
                  :outcomes-round-border="false"
                  :column="2"/>
        </div>
      </section>
      <section class="range"
               :class="{active: !transitionActive}">
        <Market :market="getMarket(getNextEvent.markets, '823')"
                :betting-shortcuts="bettingShortcuts"
                :column="7"/>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RemainingTime from '@/components/Retail/RemainingTime';
import Market from '@/components/Retail/Market';
import FlagCategoryTournament from '@/components/Retail/FlagCategoryTournament';
import { searchMarket } from '@/utility';
import EventBus from '@/store/EventBus';

export default {
  name: 'NextEvent',
  components: { FlagCategoryTournament, Market, RemainingTime },
  data() {
    return {
      bettingShortcuts: false,
      transitionActive: false,
      transitionActiveTimeout: null,
      switchBettingTimeout: null,
      inTransitionActiveTimeout: null,
      switchTimeout: null,
    };
  },
  computed: {
    ...mapGetters([
      'mainEvents',
      'translate',
      'queryParams',
    ]),
    getNextEvent() {
      return this.mainEvents[0];
    },
  },
  methods: {
    getMarket(markets, betType) {
      return searchMarket(markets, betType);
    },
    toggleTransitionActive() {
      if (this.queryParams && this.queryParams.application === 'terminal') {
        return;
      }
      this.transitionActive = true;
      this.transitionActiveTimeout = setTimeout(() => {
        this.bettingShortcuts = !this.bettingShortcuts;
        this.transitionActive = false;
        const duration = this.bettingShortcuts ? 7000 : 14000;
        this.inTransitionActiveTimeout = setTimeout(() => {
          this.toggleTransitionActive();
        }, duration);
      }, 700);
    },

    clearTimeout() {
      clearTimeout(this.switchTimeout);
      clearTimeout(this.transitionActiveTimeout);
      clearTimeout(this.inTransitionActiveTimeout);
    },
  },
  mounted() {
    EventBus.$on('stopIntervalsAndTimeouts', () => {
      this.clearTimeout();
    });
    this.switchTimeout = setTimeout(() => {
      this.toggleTransitionActive();
    }, this.$store.getters.transitionBetweenComponentDuration);
  },
  beforeDestroy() {
    this.clearTimeout();
  },
};
</script>

<style lang="scss" scoped>
  .next-event {
    height: 100%;
    .next-event-header {
      display: flex;
      justify-content: center;
      font-size: 16px;
      height: 76px;
      position: relative;
      .main-info {
        align-items: flex-end;
        display: flex;
      }
      .display-time-info {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        font-weight: 400;
        position: absolute;
        right: 0;
        top: 0;
        .display-id {
          span {
            &:nth-child(2) {
              margin-left: 3px;
            }
          }
        }
        .left-time {
          display: flex;
          margin-right: -9px;
        }
      }
    }
    .next-event-body {
      height: calc(100% - 76px);
      overflow: hidden;
    }
    .next-event-body {
      .even-total {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-column-gap: 40px;
      }
      .betting-shortcuts-title {
        height: 40px;
        @media (min-width: 1920px) {
          height: 72px;
          .betting-shortcuts {
            font-size: 28px !important;
          }
        }
        .betting-shortcuts {
          font-size: 20px;
          font-weight: 300;
          padding-top: 18px;
          text-align: center;
        }
      }
      .even-total,
      .betting-shortcuts-title > .betting-shortcuts,
      .range {
        opacity: 0;
        transition: opacity .5s linear;
        &.active {
          opacity: 1;
        }
      }
    }
    @media (min-width: 1920px) {
      .next-event-header {
        font-size: 24px;
        height: 110px;
        .left-time {
          margin-right: -20px !important;
        }
      }
      .next-event-body {
        height: calc(100% - 110px);
      }
    }
  }
</style>
