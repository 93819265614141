import { render, staticRenderFns } from "./NextEvent.vue?vue&type=template&id=533d5dc3&scoped=true&"
import script from "./NextEvent.vue?vue&type=script&lang=js&"
export * from "./NextEvent.vue?vue&type=script&lang=js&"
import style0 from "./NextEvent.vue?vue&type=style&index=0&id=533d5dc3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "533d5dc3",
  null
  
)

export default component.exports